import React, { useCallback, useEffect, useMemo } from 'react';
import { injectIntl, defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { N20 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { token } from '@atlaskit/tokens';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { Box, xcss, Flex } from '@atlaskit/primitives';

import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { sharedi18n } from '../sharedi18n';
import { ContentStateColor, ContentStateRestrictionLevel } from '../ContentStateColor';
import { colorCodeToColorName } from '../helpers';
import { determineSecondaryColor } from '../EditorContentState/helpers';

import { type RendererContentStateComponentProps } from './types';

const i18n = defineMessages({
	lastUpdated: {
		id: 'content-state.last-updated',
		description:
			'Message in tooltip when user hovers over content state that tells them when the status was last updated',
		defaultMessage: 'Last updated',
	},
	justAMomentAgo: {
		id: 'content-state.just-a-moment',
		description:
			'Default message in tooltip when user hovers over content state that tells them when the status was last updated',
		defaultMessage: 'just a moment ago',
	},
});

const rendererContentStateWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	cursor: 'default',
	minHeight: '32px',
	backgroundColor: 'color.background.neutral',
	borderRadius: '3px',
	whiteSpace: 'nowrap',
	marginLeft: 'space.075',
	marginRight: 'space.075',
});

const contentStatePaddingRight100Styles = xcss({
	paddingRight: 'space.100',
});

const contentStateNameStyles = xcss({
	color: 'color.text',
	fontWeight: 500,
});

const subtleContentStateNameStyles = xcss({
	fontSize: '12px',
	fontWeight: 'font.weight.regular',
	color: 'color.text.subtle',
});

const getBackgroundColor = (
	isContentStateVisualExperimentEnabled: boolean,
	isSubtle: boolean,
	contentStatePrimaryColor?: string | null,
) => {
	if (isSubtle) {
		if (process.env.REACT_SSR) {
			return '#00000000';
		} else {
			return token('utility.elevation.surface.current', '#FFFFFF');
		}
	}

	return isContentStateVisualExperimentEnabled
		? determineSecondaryColor(contentStatePrimaryColor)
		: token('color.background.neutral', N20);
};

const RendererContentStateComponentImpl = ({
	contentId,
	contentState,
	lastUpdatedInfo,
	isPagePreview,
	intl: { formatMessage },
}: RendererContentStateComponentProps) => {
	const updatedInfo = lastUpdatedInfo || formatMessage(i18n.justAMomentAgo);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isPageOwnerRestricted =
		contentState?.restrictionLevel ===
		ContentStateRestrictionLevel[ContentStateRestrictionLevel.PAGE_OWNER];

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'rendererContentState',
				attributes: {
					color: colorCodeToColorName(contentState?.color?.toUpperCase()),
					restrictionLevel: contentState?.restrictionLevel?.toUpperCase(),
				},
			},
		}).fire();
	}, [createAnalyticsEvent, contentState, contentId]);

	const handleMouseEnter = useCallback(() => {
		if (!isPagePreview) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'hovered',
					actionSubject: 'button',
					objectId: contentId,
					actionSubjectId: 'rendererContentStateButtonHovered',
					source: 'rendererContentStateButton',
					attributes: {
						contentId,
					},
				},
			}).fire();
		}
	}, [contentId, isPagePreview, createAnalyticsEvent]);

	const isContentStateVisualExperimentEnabled = FeatureGates.getExperimentValue(
		'content_state_visual_appearance_experiment',
		'isEnabled',
		false,
	);
	const isLivePage = useIsLivePage();
	const isNewLivePageBylineFeatureFlagEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.new-live-pages-byline',
	);
	const isCustomSitesPageTitleFFOn = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);
	const isNewLivePageByline =
		isLivePage && isCustomSitesPageTitleFFOn && isNewLivePageBylineFeatureFlagEnabled;

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
	const contentStatePrimaryColor = contentState?.color;

	const contentStateBackgroundColor = getBackgroundColor(
		isContentStateVisualExperimentEnabled,
		isNewLivePageByline,
		contentStatePrimaryColor,
	);

	const getTooltipContent = useMemo(() => {
		if (isPageOwnerRestricted) {
			return formatMessage(sharedi18n.verifiedRestricted);
		} else if (isLivePage) {
			return formatMessage(sharedi18n.pageStatus);
		}

		return `${formatMessage(i18n.lastUpdated)}: ${updatedInfo}`;
	}, [isPageOwnerRestricted, isLivePage, updatedInfo, formatMessage]);

	return (
		<Flex>
			<Tooltip content={getTooltipContent} position="bottom">
				<Box
					as="li"
					xcss={[
						rendererContentStateWrapperStyles,
						!isNewLivePageByline && contentStatePaddingRight100Styles,
					]}
					onMouseEnter={handleMouseEnter}
					testId="renderer-content-state-wrapper"
					style={{
						backgroundColor: contentStateBackgroundColor,
					}}
				>
					<ContentStateColor
						appearance={isNewLivePageByline ? 'subtleRendererContentState' : 'rendererContentState'}
						dataTestId="renderer-content-state-color"
						color={contentState?.color}
						restrictionLevel={contentState?.restrictionLevel}
					/>
					<Box xcss={[contentStateNameStyles, isNewLivePageByline && subtleContentStateNameStyles]}>
						{contentState?.name}
					</Box>
				</Box>
			</Tooltip>
		</Flex>
	);
};

export const RendererContentStateComponent = injectIntl(RendererContentStateComponentImpl);
