import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { PRIMARY_COLORS } from './colors';
import { hexToToken } from './helpers';

export enum ContentStateRestrictionLevel {
	NONE = 'NONE',
	PAGE_OWNER = 'PAGE_OWNER',
}

export type ColorAppearance =
	| 'pageHistoryContentState'
	| 'rendererContentState'
	| 'editorContentState'
	| 'contentStateButton'
	| 'pageTitleContentStateButton'
	| 'restoreContentStatesModal'
	| 'spaceAdminContentState'
	| 'subtleContentStateButton'
	| 'subtleRendererContentState';
type Appearance = {
	ColorContainer: React.ComponentType<any>;
	radius: number;
	// Props for the SVG
	scale: string;
	viewbox: string;
};

type AppearanceMap = {
	[key in ColorAppearance]: Appearance;
};

const ContentStateColorSizes: AppearanceMap = {
	pageHistoryContentState: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		ColorContainer: styled.div({
			height: '10px',
			width: '10px',
			margin: '0 5px',
		}),
		radius: 10,
		scale: 'scale(0.625)',
		viewbox: '0 0 10 10',
	},
	rendererContentState: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		ColorContainer: styled.div({
			height: token('space.150', '12px'),
			width: token('space.150', '12px'),
			margin: `0 ${token('space.100', '8px')}`,
		}),
		radius: 12,
		scale: 'scale(0.75)',
		viewbox: '0 0 12 12',
	},
	editorContentState: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		ColorContainer: styled.div({
			height: token('space.200', '16px'),
			width: token('space.200', '16px'),
			margin: '0 25px',
		}),
		radius: 16,
		scale: 'scale(1)',
		viewbox: '0 0 16 16',
	},
	contentStateButton: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		ColorContainer: styled.div({
			height: token('space.150', '12px'),
			width: token('space.150', '12px'),
			marginRight: token('space.050', '4px'),
		}),
		radius: 12,
		scale: 'scale(0.75)',
		viewbox: '0 0 12 12',
	},
	pageTitleContentStateButton: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		ColorContainer: styled.div({
			height: token('space.200', '16px'),
			width: token('space.200', '16px'),
			marginRight: token('space.075', '6px'),
		}),
		radius: 16,
		scale: 'scale(1)',
		viewbox: '0 0 16 16',
	},
	spaceAdminContentState: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		ColorContainer: styled.div({
			maxHeight: token('space.200', '16px'),
			maxWidth: token('space.200', '16px'),
			marginRight: token('space.200', '16px'),
		}),
		radius: 16,
		scale: 'scale(1)',
		viewbox: '0 0 16 16',
	},
	restoreContentStatesModal: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		ColorContainer: styled.div({
			maxHeight: token('space.200', '16px'),
			maxWidth: token('space.200', '16px'),
		}),
		radius: 16,
		scale: 'scale(1)',
		viewbox: '0 0 16 16',
	},
	subtleContentStateButton: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		ColorContainer: styled.div({
			height: token('space.075', '6px'),
			width: token('space.075', '6px'),
			marginRight: token('space.025', '2px'),
		}),
		radius: 6,
		scale: 'scale(0.75)',
		viewbox: '0 0 12 12',
	},
	subtleRendererContentState: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
		ColorContainer: styled.div({
			height: token('space.075', '6px'),
			width: token('space.075', '6px'),
			marginLeft: token('space.050', '4px'),
			marginRight: token('space.050', '4px'),
		}),
		radius: 6,
		scale: 'scale(0.75)',
		viewbox: '0 0 12 12',
	},
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlainContentStateColor = styled.div<{
	radius: number;
	color: string | undefined;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: (props) => `${props.radius}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: (props) => `${props.radius}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${props.radius}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) => props.color,
});

// The SVG defaults to 16x16
// We need to scale to different sizes
const CheckCircleSvg = (scale: string, viewbox: string, color: string, dataTestId?: string) => (
	<svg
		width="100%"
		height="100%"
		viewBox={viewbox}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		style={{ display: 'block' }}
		data-testid={dataTestId}
	>
		<g transform={scale}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.62505 0.990813C8.94443 -0.330271 7.05558 -0.330271 6.37496 0.990813C5.96598 1.78463 5.04334 2.1668 4.19284 1.89468C2.77742 1.4418 1.4418 2.77742 1.89468 4.19284C2.1668 5.04334 1.78463 5.96598 0.990813 6.37496C-0.330271 7.05558 -0.330271 8.94443 0.990813 9.62505C1.78463 10.034 2.1668 10.9567 1.89468 11.8072C1.4418 13.2226 2.77742 14.5582 4.19284 14.1053C5.04334 13.8332 5.96598 14.2154 6.37496 15.0092C7.05558 16.3303 8.94443 16.3303 9.62505 15.0092C10.034 14.2154 10.9567 13.8332 11.8072 14.1053C13.2226 14.5582 14.5582 13.2226 14.1053 11.8072C13.8332 10.9567 14.2154 10.034 15.0092 9.62505C16.3303 8.94443 16.3303 7.05558 15.0092 6.37496C14.2154 5.96598 13.8332 5.04334 14.1053 4.19284C14.5582 2.77742 13.2226 1.4418 11.8072 1.89468C10.9567 2.1668 10.034 1.78463 9.62505 0.990813ZM11.2176 5.5246L11.2951 5.59368C11.6175 5.91602 11.6405 6.42433 11.3642 6.77323L11.2951 6.85076L7.73956 10.4063C7.41722 10.7287 6.90894 10.7517 6.56003 10.4754L6.48251 10.4063L4.70473 8.62854C4.3576 8.28141 4.3576 7.71859 4.70473 7.37146C5.02707 7.04912 5.53535 7.02609 5.88425 7.30238L5.96178 7.37146L7.11117 8.52029L10.0381 5.59368C10.3604 5.27134 10.8687 5.24831 11.2176 5.5246Z"
				fill={color}
			/>
		</g>
	</svg>
);

const getDisplayedColor = (color: string, isWarning?: boolean): string => {
	if (isWarning) {
		return PRIMARY_COLORS.RED;
	}
	return hexToToken(color);
};

type ContentStateColorProps = {
	color?: string | null;
	restrictionLevel?: ContentStateRestrictionLevel | null;
	appearance: ColorAppearance;
	isWarning?: boolean;
	dataTestId?: string;
};

export const ContentStateColor: FC<ContentStateColorProps> = ({
	color,
	restrictionLevel = ContentStateRestrictionLevel.NONE,
	appearance,
	isWarning = false,
	dataTestId,
}) => {
	const { ColorContainer, radius, scale, viewbox } = ContentStateColorSizes[appearance];
	const displayedColor: string = getDisplayedColor(color ?? PRIMARY_COLORS.BLUE, isWarning);

	const isRestrictedState =
		restrictionLevel !== ContentStateRestrictionLevel[ContentStateRestrictionLevel.NONE] &&
		!isWarning;

	return (
		<ColorContainer>
			{isRestrictedState ? (
				CheckCircleSvg(scale, viewbox, displayedColor, dataTestId)
			) : (
				<PlainContentStateColor data-testid={dataTestId} radius={radius} color={displayedColor} />
			)}
		</ColorContainer>
	);
};
